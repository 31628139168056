import React from "react";
import { ReactTyped } from "react-typed";
import Button from "./Parts/Button";

const UpdatedHero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[25px] md:mt-[-20px] w-full h-[350px] md:h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] 2xl:text-4xl md:text-2xl sm:text-xl font-bold p-2">
          GROW WITH OOH ADVERTISING
        </p>
        <h1 className="2xl:text-[75px] lg:text-[65px] md:text-[50px] sm:text-6xl text-4xl font-bold md:py-6">
          Advertisers Favorite Database.
        </h1>
        <div className="flex justify-center items-center">
          <p className="lg:text-3xl md:text-xl sm:text-2xl text-xl font-bold py-3">
            Fast, Flexible, Advertising for
          </p>
          <ReactTyped
            className="lg:text-3xl md:text-xl sm:text-2xl text-xl font-bold sm:pl-2 pl-1 text-[#00df9a]"
            strings={[
              "Realtors",
              "Hotels",
              "Restaurants",
              "Events",
              "Apartments",
              "Gyms",
            ]}
            typeSpeed={80}
            backSpeed={30}
            loop
          />
        </div>
        <p className="pt-4 text-gray-500 font-bold md:text-2xl text-xl">
          Browse inventory to find flexible billboard advertising and grow your
          business.
        </p>

        {/* <ul className="flex flex-col md:flex-row px-auto py-3">
          <Link
            to="/contact"
            className="bg-[#00df9a] w-[200px] rounded-md text-black font-medium my-6 mx-auto py-3"
          >
            Contact Sales
          </Link>
        </ul> */}
        <div className="flex justify-center pt-5">
          <Button to="/contact" title="Get Started" />
        </div>
      </div>
    </div>
  );
};

export default UpdatedHero;
