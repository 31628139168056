import React from "react";
import { Link } from "react-router-dom";

const Button = ({ title, to }) => {
  return (
    <div className="inline-block">
      <Link
        to={to}
        className="bg-[#00df9a] hover:bg-gradient-to-r from-[#00df9a] to-[#30c58f] h-10 rounded-full px-6 py-3 flex items-center justify-center transition-all duration-300 ease-in-out group"
        style={{ width: "fit-content" }}
      >
        <p className="text-black font-semibold mr-2 ml-4">{title}</p>
        <span className="inline-block opacity-0 group-hover:opacity-100 translate-x-[-10px] group-hover:translate-x-0 transition-all duration-300 ease-in-out text-black">
          ➔
        </span>
      </Link>
    </div>
  );
};

export default Button;
