import React from "react";

const SocialProof = () => {
  return (
    <div className="bg-[#252525] h-auto md:h-auto w-[100%] mt-10 md:mt-0">
      <h1 className="text-[#868686] pl-5 pt-3">
        Trusted by the Worlds Leading Advertisers
      </h1>
      {/* DO NOT DELETE -- Second Social Proof Variant -- Also Still needs so fixes! (a lot of fixes) */}
      {/* <div className="flex flex-row justify-around *:h-[75px] *:md:h-[125px] *:md:pt-5 *:text-[#868686] *:text-[75px] *:font-bold">
        <div>
          <h1 className="text-white">250+</h1>
          <h2 className="flex text-[25px] font-normal justify-center">
            Providers
          </h2>
        </div>
        <div>
          <h1 className="text-white">5500+</h1>
          <h2 className="flex text-[25px] font-normal justify-center">
            Billboards
          </h2>
        </div>
        <div>
          <h1 className="text-white">$100M</h1>
          <h2 className="flex text-[25px] font-normal justify-center">
            Transacted
          </h2>
        </div>
      </div> */}
      <div className="flex flex-row justify-around *:h-[75px] *:md:h-[125px] *:md:pt-5 *:text-[#868686] *:text-[75px] *:font-bold py-5">
        <img alt="company logo" src="143logo.png" />
        <img alt="company logo" src="143logo.png" />
        <img alt="company logo" src="143logo.png" />
      </div>
    </div>
  );
};

export default SocialProof;
